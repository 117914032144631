import { render, staticRenderFns } from "./BranchesCarousel.vue?vue&type=template&id=b7d37d36&scoped=true"
import script from "./BranchesCarousel.vue?vue&type=script&lang=ts"
export * from "./BranchesCarousel.vue?vue&type=script&lang=ts"
import style0 from "./BranchesCarousel.vue?vue&type=style&index=0&id=b7d37d36&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_prettier@3.3.3_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7d37d36",
  null
  
)

export default component.exports